import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import karud from "../pics/karud.jpg";
import karudLow from "../pics/karudLow.jpg";
import polvkonnad from "../pics/polvkonnad.jpg";
import polvkonnadLow from "../pics/polvkonnadLow.jpg";
import threedogs from "../pics/threedogs.jpg";
import threedogsLow from "../pics/threedogsLow.png";
import TableOfContents from "./utilComponents/TableOfContents";
import TableOfContentsMobile from "./utilComponents/TableOfContentsMobile";
import meeting from "../pics/meeting.jpg";
import meetingLow from "../pics/meetingLow.jpg";
import quercino from "../pics/ilquercino.jpg";
import quercinoLow from "../pics/ilquercinoLow.png";
import otto from "../pics/otto.jpg";
import ottoLow from "../pics/ottoLow.jpg";
import tess from "../pics/tess.jpg";
import tessLow from "../pics/tessLow.jpg";
import truhvod from "../pics/truhvod.jpg";
import truhvodLow from "../pics/truhvodLow.jpg";
import { Helmet } from "react-helmet-async";
interface LagottosProps {
  windowWidth: number;
}
const Lagottos: React.FunctionComponent<LagottosProps> = (props) => {
  const windowWidth = props.windowWidth;

  const topLeftStyle = {
    backgroundColor: "#ecd3ac",
    borderRadius: windowWidth > 1000 ? "20px 0px 0px 0px" : "20px 20px 0px 0px",
  };

  const upperPicRadius =
    windowWidth > 1000 ? "0px 20px 0px 0px" : "0px 0px 0px 0px";
  const lowerPicRadius =
    windowWidth > 1000 ? "0px 0px 0px 20px" : "0px 0px 20px 20px";

  const bottomRightStyle = {
    backgroundColor: "#ecd3ac",
    borderRadius: windowWidth > 1000 ? "0px 0px 20px 0px" : "0px 0px 0px 0px",
  };
  const columnWidth = windowWidth > 1000 ? "6" : "12";

  const tableOfContents =
    windowWidth > 1000 ? (
      <Col>
        <TableOfContents />
      </Col>
    ) : (
      <Row className="w-100">
        <TableOfContentsMobile />
      </Row>
    );

  const picHeight =
    windowWidth > 1000 ? "30rem" : windowWidth > 850 ? "25rem" : "15rem";
  const topPadding =
    windowWidth > 1000
      ? {
          boxShadow: "0px 0px 30px gray",
          borderRadius: "20px",
          marginTop: "3rem",
        }
      : {
          boxShadow: "0px 0px 30px gray",
          borderRadius: "20px",
          marginTop: "4rem",
        };
  return (
    <Container
      className={
        windowWidth > 1000
          ? "page-wrapper d-flex flex-row justify-content-center pb-4"
          : "page-wrapper d-flex flex-column align-items-center pb-4"
      }
      style={{ paddingTop: "3rem" }}
    >
      <Helmet>
        <title>Lagotto Tutvustus</title>
        <meta
          name="description"
          content="Eesti Romagna Veekoerte Tõuühingu poolt välja töötatud lagotto romagnolo täielik tutvustus. Juttu on Lagottost endast, lagotto ajaloost, lagotto iseloomust ja lagotto karvastikust"
        />
      </Helmet>
      {tableOfContents}
      <Col
        className="d-flex flex-column align-items-center"
        style={topPadding}
        xs="10"
      >
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column flex-nowrap"
          }
        >
          <Col style={topLeftStyle} xs={columnWidth}>
            <h5 className="py-4 scroll-to-chapter" id="lagottoChapter">
              Lagotto
            </h5>
            <p className="pb-5">
              Romagna veekoer ehk lagotto romagnolo on keskmist kasvu iidne
              töökoer Põhja-Itaaliast, keda on kasutatud veelindude jahis, aga
              tänapäeval on nad eelkõige tuntud kui trühvliotsijad. Oma
              välimuselt on nad suhteliselt sarnased oma eelkäijatega mistõttu
              võib öelda, et aja jooksul on nende välimus väga vähe muutunud.
              Romagna veekoer on oma nime saanud osalt Romagna provintsi, osalt
              sõna lago järgi, mis tähendab itaalia keeles järve.
            </p>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                borderRadius: upperPicRadius,
              }}
              src={polvkonnad}
              placeholderSrc={polvkonnadLow}
              effect="blur"
              alt="Mitu erinevat põlvkondi lagottosid istuvad kõrvuti"
            />
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column-reverse flex-nowrap"
          }
        >
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            style={{ backgroundColor: "#fbf6ee" }}
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                objectPosition: "50% 100%",
              }}
              src={meeting}
              placeholderSrc={meetingLow}
              effect="blur"
              alt="Vana maal koosolekust, all nurgas istub lagotto"
            />
          </Col>
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <h5 className="py-4 scroll-to-chapter" id="ajalooChapter">
              Ajalugu
            </h5>
            <p className="pb-1">
              Lagotto on väga vana tõug ning ning teda peetakse kõikide
              veekoerte tõenäoliseks esivanemaks, tema juured viivad tagasi 5-15
              sajandisse. Paljudes arheoloogilistes väljakaevamistes, eelkõige
              Loode-Itaalias, on avastatud mitmete erinevate koeratõugude
              säilmeid, eriti väikeste harjaselise ja lainja karvaga veekoerte
              omi, keda peetakse romagna veekoera eelkäijateks.
            </p>
            <p>
              Vanim teadaolev maal, millelt võib leida lagotto, on pärit aastast
              1474. See on Itaalia fresko, mille maalis Andrea Mantegna ning
              mille pealkiri on „The Meeting“. Sellel pildil on näha väikest
              koera all vasakus nurgas, kes meenutab lagottot.
            </p>
            <div className="w-100 d-flex flex-column align-items-start">
              <i>Andrea Mantegna „The Meeting“</i>
            </div>
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column flex-nowrap"
          }
        >
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <p className="pb-1 mt-3">
              Mõned sajandid hiljem (1600ndad) kujutab Giovanni Francesco
              Barbieri maal „il Guercino“ juba selgelt lagotto romagnolo
              eelkäijat.
            </p>
            <p>
              Algselt on lagottod pärit Ravenna soodest ja Comacchio madalikest,
              mis paiknesid Romagna provintsis, Põhja-Itaalias. Lagottod
              valvasid paati ja maja ning suutsid aporteerida veelinde tunde ka
              külmast veest. Kui 19 sajandi lõpus sood kuivendati ning
              põllumaaks muudeti, siis veelinnud kadusid ning lagottod kaotasid
              oma aporteeriva jahikoera funktsiooni. Nende kõrgelt arenenud
              lõhnatundlikkuse tõttu tõestasid nad ennast aga kiiresti trühvlite
              otsimises.
            </p>
            <div className="w-100 d-flex flex-column align-items-end">
              <i>Giovanni Francesco Barberi „il Guercino“</i>
            </div>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                objectPosition: "45%",
              }}
              src={quercino}
              placeholderSrc={quercinoLow}
              effect="blur"
              alt="Maal istuvast lagottost"
            />
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column-reverse flex-nowrap"
          }
        >
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            style={{ backgroundColor: "#fbf6ee" }}
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                objectPosition: "50% 100%",
              }}
              src={karud}
              placeholderSrc={karudLow}
              effect="blur"
              alt="Kolm sõbralikku lagottot istuvad metsatuka ääres"
            />
          </Col>
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <p className="pb-1 mt-3">
              1920ndateks oli siiski tõu arvukus langenud märkimisväärselt. Neid
              kasutati sageli ristamiseks teiste tõugudega nende jahi- ja
              lõhnatundmisoskuse pärast ning seetõttu puhtatõuliste lagottode
              populatsioon kahanes. 1970datel kui tõugu ähvardas väljasuremine
              otsustasid tõu entusiastid tõu päästa. Sinna kuulusid eesotsas
              Quintino Toschi, Prof. Francesco Ballotta, Dr. Antonio Morsiani ja
              Lodovico Babini.
            </p>
            <p>
              1988 loodi Imolas C.I.L. (Club Italiano Lagotto). Dr. Antonio
              Morsiani poolt koostatud standard kiideti heaks 1992 ning 1995
              tunnustas tõugu ametlikult ka F.C.I.
            </p>
            <p>
              1997 loodi U.M.L.A.G (World Union of Lagotto Clubs – Unione
              Mondiale dei Club Lagotto Romagnolo), mis ühendab endas kõiki
              rahvusvahelisi Lagotto Klubisid ning mille president on Dr.
              Giovanni Morsiani.
            </p>
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column flex-nowrap"
          }
        >
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <h5 className="py-4 scroll-to-chapter" id="iseloomChapter">
              Iseloom ja välimus
            </h5>
            <p className="pb-1">
              Lagotto on tüüpiline veekoer – väikest kuni keskmist kasvu,
              ruudukujulise kehaga, tugeva kehaehitusega ning rustikaalse
              väljanägemisega. Tööd teevad entusiastlikult ning pühendumusega.
              Lagotto tüüpiline väljanägemine on väga sarnane tema ajaloolise
              eellasega, mis on imekombel säilinud tänase päevani.
            </p>
            <p>
              Romagna veekoer on ainuke ametlikult tunnustatud tõug, mis on
              spetsialiseerunud trühvlite otsimiseks. Nende suurus, väledus,
              soov meeldida ning vetthülgav karvastik sobivad selle töö jaoks
              valatult. Nad ei ole aga ka kaotanud oma aporteerimise oskust nii
              veest kui maismaalt. Kuna nende jahiinstinkt on hääbunud siis
              suudavad nad keskenduda töötamise ajal vaid trühvlite otsimisele
              ning kõrvaline ulukilõhn neid ei sega.
            </p>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
              }}
              src={threedogs}
              placeholderSrc={threedogsLow}
              effect="blur"
              alt="Kolm lagotto romagnolo istuvad itaalia mägede ees"
            />
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column-reverse flex-nowrap"
          }
        >
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            style={{ backgroundColor: "#fbf6ee" }}
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                objectPosition: "50% 100%",
              }}
              src={otto}
              placeholderSrc={ottoLow}
              effect="blur"
              alt="Lagotto Romagnolo kutsikas lamab männikus"
            />
          </Col>
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <p className="pb-1 mt-3">
              Iseloomult on lagotto kergesti käsitletav, elav, intelligentne
              ning tähelepanelik. Väga oma omanikuga seotud ning kergesti
              koolitatav. Võõraste suhtes võivad olla veidi reserveeritud, aga
              oma perele on lagotto väga lojaalne ning armastusväärne, mistõttu
              peetakse neid ka headeks seltsikoerteks ning kuna nad võivad olla
              suhteliselt häälekad, siis ka suurepärasteks valvekoerteks. Paljud
              lagottod armastavad vett ja ujumist, samuti meeldib neile kaevata,
              mistõttu tuleks sellega kindlasti arvestada.
            </p>
            <p>
              Turjakõrgus isastel 43-48 cm (ideaalne 46), emastel 41-46 cm
              (ideaalne 43). Isased 13-16 kg, emased 11-14 kg.
            </p>
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column flex-nowrap"
          }
        >
          <Col style={{ backgroundColor: "#ecd3ac" }} xs={columnWidth}>
            <h5 className="py-4 scroll-to-chapter" id="karvaChapter">
              Karvastik
            </h5>
            <p className="pb-1">
              Karvkate on tihe, lokkis ja villataoline, aga mitte kunagi
              keerdunud tihedateks nöörideks. Pealispinnalt veidi kare, tihedate
              ringikujuliste lokkidega, mis lasevad alusvillal läbi paista.
            </p>
            <p>
              Lokid peavad olema kogu kehal ühtlaselt jaotunud, välja arvatud
              pea, kus karv on rohkem lainjas ning ei moodusta nii tihedaid
              lokke. Kattekarv ja eriti aluskarv on vettpidavad. Kui karva ei
              pügata, siis kaldub see vildistuma, mistõttu tuleks vähemalt korda
              aastas karv maha ajada. Vildistunud osad kattekarvast ning
              alusvillast tuleb regulaarselt eemaldada.
            </p>
          </Col>
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
              }}
              src={tess}
              placeholderSrc={tessLow}
              effect="blur"
              alt="Lagotto seisab keset eesti metsa"
            />
          </Col>
        </Row>
        <Row
          className={
            windowWidth > 1000
              ? "d-flex flex-row flex-nowrap"
              : "d-flex flex-column-reverse flex-nowrap"
          }
        >
          <Col
            className="d-flex flex-column align-items-center justify-content-center p-0"
            style={{ backgroundColor: "#fbf6ee" }}
            xs={columnWidth}
          >
            <LazyLoadImage
              style={{
                objectFit: "cover",
                height: picHeight,
                width: "100%",
                zIndex: "inherit",
                borderRadius: lowerPicRadius,
                objectPosition: "50% 100%",
              }}
              src={truhvod}
              placeholderSrc={truhvodLow}
              effect="blur"
              alt="Lagotto romagnolo kutsikad peale trühvlijahti"
            />
          </Col>
          <Col style={bottomRightStyle} xs={columnWidth}>
            <p className="pb-1 mt-3">
              Karvakatte värvuseid on väga erinevaid, tüüpilisemad on
              määrdunudvalge, valge oranžide või pruunide märgistega, oranž
              roan, pruun roan, pruun (erinevates variatsioonides) valgega või
              ilma, oranž valgega või ilma. Mõnel koeral esineb tume kuni
              tumepruun mask. Samuti on lubatud tan märgised.
            </p>
            <p>
              Kuna lagotto ei aja karva, siis tuleb neid regulaarselt pügada.
              Kui karva piisavalt sageli trimmida ning hoida see puhas, siis
              võib lagotto sobida ka allergikutele.
            </p>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default Lagottos;
