import * as React from "react";
import { Card, Navbar, Col, Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, NavLink } from "react-router-dom";
import logo from "../pics/logo.png";

const HeaderFull: React.FunctionComponent = (props) => {
  return (
    <Card style={{ backgroundColor: "#ecd3ac" }}>
      <Navbar
        fixed="top"
        className="d-flex w-100 flex-column header-content-wrapper pt-2"
      >
        <div className="d-flex w-100 flex-row" style={{ paddingLeft: "4rem" }}>
          <div className="d-flex flex-row justify-content-start pb-0">
            <Link to="/">
              <LazyLoadImage
                className="header-logo"
                src={logo}
                effect="blur"
                alt="logo"
              />
            </Link>
            <Col className="d-flex flex-column pt-3">
              <Navbar.Brand className="pb-0 mb-0">
                <p className="main-logo-full mb-0 pb-0">
                  {"Eesti Romagna Veekoerte Tõuühing".toUpperCase()}
                </p>
              </Navbar.Brand>
              <div className="d-flex flex-row justify-content-start flex-nowrap w-100 headerfull-menuitems-wrapper">
                <NavLink to="/" className="menu-item">
                  Avaleht
                </NavLink>
                <NavLink to="uhingust" className="menu-item">
                  Ühingust
                </NavLink>
                <NavLink to="lagotto" className="menu-item">
                  Lagotto
                </NavLink>
                <NavLink to="tervis" className="menu-item">
                  Tervis
                </NavLink>
                <NavLink to="pesakonnad" className="menu-item">
                  Pesakonnad
                </NavLink>
                <NavLink to="events" className="menu-item">
                  Üritused
                </NavLink>
                <NavLink to="kontakt" className="menu-item">
                  Kontakt
                </NavLink>
              </div>
            </Col>
          </div>
          <Col className="d-flex flex-row justify-content-end join-button-wrapper">
            <Link to={"liitu"}>
              <Button id="join-button">TULE LIIKMEKS</Button>
            </Link>
          </Col>
        </div>
      </Navbar>
    </Card>
  );
};

export default HeaderFull;
