import * as React from "react";
import {
  Row,
  Card,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasTitle,
  OffcanvasBody,
  Col,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import logo from "../pics/logo.png";
import { HeaderProps } from "./Header";

const HeaderMobile: React.FunctionComponent<HeaderProps> = (props) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [mouseOn, setMouseOn] = React.useState(false);

  const toggleHover = () => setMouseOn(!mouseOn);
  const toggleNavbar = () => setMenuOpen(!menuOpen);
  let buttonStyle = !mouseOn ? { color: "#7F603A" } : { color: "#EE9781" };

  return (
    <Card>
      <div className="d-flex w-100 flex-column mobile-header-wrapper pt-2 pb-2">
        <div className="d-flex flex-column align-items-center">
          <Row className="d-flex flex-row justify-content-between w-100 flex-nowrap">
            <Col className="pe-0">
              <Link to="/" className="position-absolute" style={{top: "5px", left: "10px"}}>
                <LazyLoadImage
                  className="header-logo-mobile"
                  src={logo}
                  effect="blur"
                  alt="ERVT ametlik logo"
                  style={{height: "100px"}}
                />
              </Link>
            </Col>
            <Col className="d-flex flex-column pt-1 align-items-end">
              <Button
                style={{
                  backgroundColor: "#ecd3ac",

                  border: "none",
                }}
                onClick={toggleNavbar}
              >
                <i
                  className="bi bi-list mobile-menu-button"
                  style={buttonStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                ></i>
              </Button>
            </Col>
          </Row>
        </div>

        <Offcanvas
          show={menuOpen}
          onHide={toggleNavbar}
          placement="end"
          scroll={true}
          style={{ backgroundColor: "#ffd485", width: "100%" }}
        >
          <OffcanvasHeader closeButton style={{ paddingBottom: "0" }}>
            <OffcanvasTitle />
          </OffcanvasHeader>
          <OffcanvasBody>
            <div
              className="d-flex flex-column align-items-center flex-nowrap"
              style={{
                marginBottom: "1px",
                textAlign: "center",
              }}
            >
              <Link to="/" className="menu-item-mobile" onClick={toggleNavbar}>
                Avaleht
              </Link>
              <Link
                to="uhingust"
                className="menu-item-mobile"
                onClick={toggleNavbar}
              >
                Ühingust
              </Link>
              <Link
                to="lagotto"
                className="menu-item-mobile"
                onClick={toggleNavbar}
              >
                Lagotto
              </Link>
              <Link
                to="tervis"
                className="menu-item-mobile"
                onClick={toggleNavbar}
                style={{paddingRight: "5rem"}}
              >
              Tervis
              </Link>
              <Link
                to="pesakonnad"
                className="menu-item-mobile"
                onClick={toggleNavbar}
              >
                Pesakonnad
              </Link>
              <Link
                to="events"
                className="menu-item-mobile"
                onClick={toggleNavbar}
              >
                Üritused
              </Link>
              <Link
                to="/kontakt"
                className="menu-item-mobile"
                onClick={toggleNavbar}
              >
                Kontakt
              </Link>
              <Row className="d-flex flex-row justify-content-center mb-2 mt-5">
                <Link to={"liitu"}>
                  <Button
                    style={{
                      width: "15rem",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                    id="join-button"
                    onClick={toggleNavbar}
                  >
                    TULE LIIKMEKS
                  </Button>
                </Link>
              </Row>
            </div>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </Card>
  );
};

export default HeaderMobile;
