import * as React from 'react';
import TableOfContentsButtons from './TableOfContentsButtons';


const TableOfContents: React.FunctionComponent = () => {

        return (
          <div
            className="py-2 px-4 d-flex flex-column"
            style={{
              position: "fixed",
            }}
          >
            <TableOfContentsButtons />
          </div>
        );
    }

export default TableOfContents;