import * as React from "react";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { scrollToChapter } from "../../util/TableOfContentsUtil";

const dropDownLabel = (
  <h2 style={{marginRight: ".3rem"}}>
    <i className="fa fa-book TOC-button-label"></i>
  </h2>
);

const TableOfContentsMobile: React.FunctionComponent = () => {

    const isOpen = React.useRef(false);

    return (
      <div
        className="w-100 d-flex flex-row justify-content-start"
        style={{ position: "fixed" }}
      >
        <Dropdown show={isOpen.current}>
          <DropdownButton id="TOCButton" title={dropDownLabel}>
            <div style={{ backgroundColor: "#fbf6ee", borderRadius: "10px" }}>
              <Dropdown.Item
                id="chapterDropdownItem"
                onClick={() => scrollToChapter("lagottoChapter")}
              >
                | Lagotto
              </Dropdown.Item>
              <Dropdown.Item
                id="chapterDropdownItem"
                onClick={() => scrollToChapter("ajalooChapter")}
              >
                | Ajalugu
              </Dropdown.Item>
              <Dropdown.Item
                id="chapterDropdownItem"
                onClick={() => scrollToChapter("iseloomChapter")}
              >
                | Iseloom
              </Dropdown.Item>
              <Dropdown.Item
                id="chapterDropdownItem"
                onClick={() => scrollToChapter("karvaChapter")}
              >
                | Karvastik
              </Dropdown.Item>
            </div>
          </DropdownButton>
        </Dropdown>
      </div>
    );
};

export default TableOfContentsMobile;
