import * as React from 'react';
import { scrollToChapter } from '../../util/TableOfContentsUtil';

const TableOfContentsButtons: React.FunctionComponent = () => {
    return (
      <>
        <button
          id="chapterButton"
          onClick={() => scrollToChapter("lagottoChapter")}
        >
          | Lagotto
        </button>
        <button
          id="chapterButton"
          onClick={() => scrollToChapter("ajalooChapter")}
        >
          | Ajalugu
        </button>
        <button
          id="chapterButton"
          onClick={() => scrollToChapter("iseloomChapter")}
        >
          | Iseloom
        </button>
        <button
          id="chapterButton"
          onClick={() => scrollToChapter("karvaChapter")}
        >
          | Karvastik
        </button>
      </>
    );
}

export default TableOfContentsButtons;