import * as React from "react";
import HeaderFull from "./HeaderFull";
import HeaderMobile from "./HeaderMobile";

export interface HeaderProps {
  windowWidth: number;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const windowWidth = props.windowWidth;

  return windowWidth > 1000 ? <HeaderFull /> : <HeaderMobile windowWidth={windowWidth}/>;
};

export default Header;
