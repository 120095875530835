/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import { Card, Container } from "react-bootstrap";
import "../styles/styles.scss";
import pohikiriPdf from "../assets/MTÜ_EESTI_ROMAGNA_VEEKOERTE_TÕUÜHINGU_põhikiri.pdf";
import eetikakoodeksPdf from "../assets/MTÜ_EEESTI_ROMAGNA_VEEKOERTE_TÕUÜHINGU_eetikakoodeks.pdf";


const Footer: React.FunctionComponent = (props) => {
    return (
      <Card
        className="text-center"
        style={{
          background: "#ecd3ac",
          marginTop: "2rem",
          borderRadius: "5px 5px 0px 0px",
          width: "100%",
        }}
      >
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        ></link>
        <Container className="container-fluid p-4 pb-0">
          <div className="d-flex justify-content-center mb-3">
            <h3 className="px-3">
              <a
                href="https://www.facebook.com/profile.php?id=100091422675967"
                rel="noreferrer"
                target="_blank"
                className="fa fa-facebook social-icon"
              ></a>
            </h3>
          </div>
        </Container>
        <div className="text-center p-1 pt-0">
          <a
            href={pohikiriPdf}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            ERVT Põhikiri
          </a>
          <br />
          <a
            href={eetikakoodeksPdf}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            ERVT Eetikakoodeks
          </a>
        </div>
        <div className="text-center p-1 pt-0">
          © 2023 Copyright Eesti Romagna Veekoerte Tõuühing
        </div>
      </Card>
    );
}

export default Footer;